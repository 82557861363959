<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('jobs')" :is-filter="false">
                </Header>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('jobs')" :is-filter="false">
                </HeaderMobile>
            </template>
            <div class="row data-form">
                <div class="col-12">
                    <ValidationObserver ref="form">
                        <div class="row">
                            <div class="col-12">
                                <ValidationProvider name="job" rules="required" v-slot="{ valid, errors }">
                                    <b-form-group :label="$t('process')">
                                        <multi-selectbox :allow-empty="false" :multiple="false"
                                            :validate-error="errors[0]" :options="jobOptions"
                                            v-model="form.job"></multi-selectbox>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12">
                                <div class="row">
                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4" v-if="showSemester">
                                        <ValidationProvider name="semester_id" rules="" v-slot="{ valid, errors }">
                                            <b-form-group :label="$t('semester')">
                                                <semesters-selectbox :multiple="false" :validate-error="errors[0]"
                                                    :setActive="true" v-model="form.semester_id">
                                                </semesters-selectbox>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                </div>
                            </div>

                            <div class="col-12">
                                <div class="row">
                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4"
                                        v-if="['job_prep_student_period_status_create'].includes(form.job)">
                                        <ValidationProvider name="semester_id" rules="required" v-slot="{ errors }">
                                            <b-form-group :label="$t('academic_year')">
                                                <academic-years-selectbox :validate-error="errors[0]"
                                                    v-model="form.academic_year" />
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4"
                                        v-if="['job_prep_student_period_status_create'].includes(form.job)">
                                        <ValidationProvider name="semester_id" rules="required" v-slot="{ errors }">
                                            <b-form-group :label="$t('module')">
                                                <module-selectbox valueType="id" :academic_year="form.academic_year"
                                                    :validate-error="errors[0]" v-model="form.module_id" />
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                </div>
                            </div>

                            <div class="col-12" v-if="showNumbers">
                                <ValidationProvider name="number" rules="" v-slot="{ valid, errors }">
                                    <b-form-group :label="$t('student_numbers')">
                                        <lined-textarea v-model="form.number" :nowrap="false" :disabled="false"
                                            :styles="{ height: '15em', resize: 'both' }" :validateError="errors[0]">
                                        </lined-textarea>
                                        <div class="invalid-feedback d-block" v-if="errors[0]" v-html="errors[0]"></div>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>


                            <div class="col-12 col-md-6"
                                v-if="['auto_course_registration', 'auto_repeat_course_registration', 'max_duration_information_for_advisor'].includes(form.job)">
                                <ValidationObserver>
                                    <ValidationProvider name="faculty_code" rules="" v-slot="{ valid, errors }">
                                        <b-form-group :label="$t('faculty')">
                                            <faculty-selectbox v-model="faculty_code" :validate-error="errors[0]" />
                                        </b-form-group>
                                    </ValidationProvider>
                                </ValidationObserver>
                            </div>
                            <div class="col-12 col-md-6"
                                v-if="['max_duration_information_for_advisor'].includes(form.job)">
                                <ValidationProvider name="department_code" rules="" v-slot="{ valid, errors }">
                                    <b-form-group :label="$t('department')">
                                        <department-selectbox v-model="department_code" :multiple="true"
                                                              :faculty_code="faculty_code" :validate-error="errors[0]" />
                                    </b-form-group>
                                </ValidationProvider>
                            </div>
                            <div class="col-12 col-md-6"
                                v-if="['max_duration_information_for_advisor'].includes(form.job)">
                                <ValidationProvider name="program_code" rules="" v-slot="{ valid, errors }">
                                    <b-form-group :label="$t('program')">
                                        <program-selectbox v-model="program_code" :faculty_code="faculty_code"
                                                           :department_code="department_code" :multiple="true" :validate-error="errors[0]" />
                                    </b-form-group>
                                </ValidationProvider>
                            </div>
                            <div class="col-12 col-md-6"
                                v-if="['max_duration_information_for_advisor'].includes(form.job)">
                                <ValidationProvider name="advisor_id" rules="" v-slot="{ errors}">
                                    <b-form-group :label="$t('advisor')">
                                        <staff-auto-suggest v-model="advisor_id" :validate-error="errors[0]" open-direction="top"/>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>
                            <div class="col-12 col-md-6"
                                 v-if="['max_duration_information_for_advisor',
                                  'assign_doesnt_have_payment_approval', 'assign_doesnt_have_course_registration'].includes(form.job)">
                                <ValidationProvider name="program_level" rules="" v-slot="{ valid, errors }">
                                    <b-form-group :label="$t('level')">
                                        <parameter-selectbox v-model="program_level" :multiple="true"
                                                             :validate-error="errors[0]" code="program_levels"></parameter-selectbox>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>
                            <div class="col-12 col-md-6"
                                 v-if="['max_duration_information_for_advisor'].includes(form.job)">
                                <ValidationProvider name="registration_type" rules="" v-slot="{valid, errors}">
                                  <b-form-group :label="$t('registration_type_1')">
                                    <parameter-selectbox v-model="registration_type"
                                                         code="registration_types"></parameter-selectbox>
                                  </b-form-group>
                                </ValidationProvider>
                            </div>
                            <div class="col-12 col-md-6"
                                 v-if="['max_duration_information_for_advisor'].includes(form.job)">
                                <ValidationProvider name="customer_group" rules="" v-slot="{valid, errors}">
                                  <b-form-group :label="$t('student_group')">
                                    <multi-selectbox
                                        :multiple="false"
                                        :options="[{value:'TR', text:'TR'}, {value:'YU', text:'YU'}]"
                                        :validate-error="errors[0]"
                                        v-model="customer_group">
                                    </multi-selectbox>
                                  </b-form-group>
                                </ValidationProvider>
                            </div>
                            <div class="col-12 col-md-6"
                                 v-if="['auto_course_registration', 'max_duration_information_for_advisor'].includes(form.job)">
                                <ValidationObserver>
                                    <ValidationProvider name="class" rules="" v-slot="{ valid, errors }">
                                        <b-form-group :label="$t('class')">
                                            <parameter-selectbox code="classes" v-model="form.class"></parameter-selectbox>
                                        </b-form-group>
                                    </ValidationProvider>
                                </ValidationObserver>
                            </div>

                            <div class="col-12 col-md-6"
                                 v-if="['auto_course_registration'].includes(form.job)">
                                <ValidationObserver>
                                    <ValidationProvider name="course_type" rules="" v-slot="{ valid, errors }">
                                        <b-form-group :label="$t('course_type')">
                                            <parameter-selectbox code="course_types" v-model="form.course_type"></parameter-selectbox>
                                        </b-form-group>
                                    </ValidationProvider>
                                </ValidationObserver>
                            </div>

                            <div class="col-12 col-md-6"
                                v-if="['auto_course_registration', 'auto_repeat_course_registration', 'job_prep_student_period_status_create', 'bulk_address_update', 'registrations_bulk_delete'].includes(form.job)">
                                <ValidationProvider name="student_numbers" rules="" v-slot="{ errors }">
                                    <b-form-group :label="$t('student_numbers')">
                                        <lined-textarea v-model="student_numbers" :nowrap="false" :disabled="false"
                                            :styles="{ height: '124px' }" :validate-error="errors[0]" />
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12 mb-4" v-if="['bulk_student_transfer'].includes(form.job)">
                                <import-form />
                            </div>
                            <div class="col-12 mb-4" v-if="['edit_class_information'].includes(form.job)">
                                <student-edit-import />
                            </div>
                            <div class="col-12 mb-4" v-if="['bulk_translation'].includes(form.job)">
                                <bulk-translation-import />
                            </div>
                            <div class="col-12 mb-4"
                                v-if="['student_programs_graduation_requirement'].includes(form.job)">
                                <student-programs-graduation-requirement />
                            </div>
                            <div class="col-12 mb-4" v-if="['scholarships_import'].includes(form.job)">
                                <scholarships-import />
                            </div>
                            <div class="col-12 mb-4" v-if="['registrations_page_bulk_student_transfer'].includes(form.job)">
                                <RegistrationsBulkStudenTransfer />
                            </div>

                            <div class="col-12">
                                <b-button @click="start" label="start_process"
                                    v-show="!['scholarships_import', 'bulk_student_transfer', 'edit_class_information', 'bulk_translation', 'student_programs_graduation_requirement','registrations_page_bulk_student_transfer'].includes(form.job)"></b-button>
                            </div>
                        </div>
                    </ValidationObserver>
                </div>
            </div>

        </app-layout>
    </div>
</template>
<script>
import AppLayout from "@/layouts/AppLayout"
import Header from '@/layouts/AppLayout/Header'
import HeaderMobile from '@/layouts/AppLayout/HeaderMobile'
import { ValidationObserver, ValidationProvider } from "vee-validate"
import MultiSelectbox from "@/components/interactive-fields/MultiSelectbox"
import JobService from "@/services/JobService";
import LinedTextarea from "../../../components/elements/LinedTextarea"
import SemestersSelectbox from "../../../components/interactive-fields/SemestersSelectbox"
import qs from "qs"
import AcademicYearsSelectbox from "@/components/interactive-fields/AcademicYearsSelectbox"
import ModuleSelectbox from "@/components/interactive-fields/ModuleSelectbox"
import ImportForm from "@/modules/jobs/pages/import/importForm.vue";
import studentEditImport from "@/modules/jobs/pages/import/studentEditImport";
import BulkTranslationImport from "@/modules/jobs/pages/import/bulkTranslationImport.vue";
import StudentProgramsGraduationRequirement from "@/modules/jobs/pages/import/studentProgramsGraduationRequirement.vue";
import ScholarshipsImport from "@/modules/jobs/pages/import/scholarshipsImport.vue";
import RegistrationsBulkStudenTransfer from "@/modules/jobs/pages/import/registrationsBulkStudenTransfer.vue";
import StaffAutoSuggest from "@/components/interactive-fields/StaffAutoSuggest.vue";

export default {
    name: "JobsStart",
    components: {
        StudentProgramsGraduationRequirement,
        BulkTranslationImport,
        ImportForm,
        SemestersSelectbox,
        LinedTextarea,
        MultiSelectbox,
        AppLayout,
        ValidationProvider,
        ValidationObserver,
        Header,
        HeaderMobile,
        AcademicYearsSelectbox,
        ModuleSelectbox,
        studentEditImport,
        ScholarshipsImport,
        RegistrationsBulkStudenTransfer,
        StaffAutoSuggest,
    },
    metaInfo() {
        return {
            title: this.$t('jobs')
        }
    },
    data() {
        return {
            showSemester: false,
            showNumbers: false,
            faculty_code: null,
            department_code: null,
            program_code: null,
            advisor_id: null,
            program_level: null,
            registration_type: null,
            customer_group: null,
            student_numbers: null,
            isFile: null,
            form: {},
            jobOptions: [
                { value: 'calculate_extra_graduate_courses', text: this.$t('calculate_extra_graduate_courses') },
                { value: 'auto_course_registration', text: this.$t('auto_course_registration') },
                { value: 'auto_repeat_course_registration', text: this.$t('auto_repeat_course_registration') },
                { value: 'auto_irregular_course_registration', text: this.$t('auto_irregular_course_registration') },
                { value: 'create_student_semesters', text: this.$t('create_student_semesters') },
                { value: 'assign_doesnt_have_payment_approval', text: this.$t('assign_doesnt_have_payment_approval') },
                {
                    value: 'assign_doesnt_have_course_registration',
                    text: this.$t('assign_doesnt_have_course_registration')
                },
                {
                    value: 'delete_empty_summer_student_semester',
                    text: this.$t('delete_empty_summer_student_semester')
                },
                {
                    value: 'job_prep_student_period_status_create',
                    text: this.$t('job_prep_student_period_status_create')
                },
                { value: 'bulk_address_update', text: this.$t('bulk_address_update') },
                {
                    value: 'foreign_student_community_internship_assignment',
                    text: this.$t('foreign_student_community_internship_assignment')
                },
                {
                    value: 'registrations_bulk_delete',
                    text: this.$t('registrations_bulk_delete')
                },
                {
                    value: 'course_drop_process',
                    text: this.$t('course_drop_process')
                },
                {
                    value: 'bulk_student_transfer',
                    text: this.$t('bulk_student_transfer')
                },
                {
                    value: 'edit_class_information',
                    text: this.$t('edit_class_information')
                },
                {
                    value: 'bulk_translation',
                    text: this.$t('bulk_translation')
                },
                {
                    value: 'update_summer_school_paid_status',
                    text: this.$t('update_summer_school_paid_status')
                },
                {
                    value: 'student_programs_graduation_requirement',
                    text: this.$t('student_programs_graduation_requirement')
                },
                {
                    value: 'section_for_repeats',
                    text: this.$t('section_for_repeats')
                },
                {
                    value: 'scholarships_import',
                    text: this.$t('scholarships_import')
                },

                {
                    value: 'registrations_page_bulk_student_transfer',
                    text: this.$t('registrations_page_bulk_student_transfer')
                },
                { value: 'max_duration_information_for_advisor', text: this.$t('max_duration_information_for_advisor') },
                { value: 'search_kps_by_student_numbers', text: this.$t('search_kps_by_student_numbers') },
                { value: 'calculate_number_of_semesters', text: this.$t('calculate_number_of_semesters') },


            ],
            formProcess: null,
        }
    },
    methods: {
        formClear() {
            this.formProcess = null
        },
        async start() {
            const isValid = await this.$refs.form.validate();
            if (isValid) {
                let config = { params: this.form };
                let oldNumber = this.form.number;
                this.student_numbers=oldNumber;
                if (oldNumber) {
                    config.params.number = config.params.number.split(/\n/);
                }

                this.$swal.fire({
                    text: this.$t('are_you_sure_to_start_process'),
                    showCancelButton: true,
                    confirmButtonText: this.$t('yes'),
                    cancelButtonText: this.$t('no')
                })
                    .then((result) => {
                        if (result.isConfirmed) {
                            if (this.form.job == 'calculate_extra_graduate_courses') {
                                JobService.calculateExtraGraduateCourses().then(this.callback).catch(e => {
                                    this.showErrors(e, this.$refs.form);
                                });
                            } else if (this.form.job == 'auto_course_registration') {
                                const config = {
                                    params: {
                                        faculty_code: this.faculty_code,
                                        class: this.form.class,
                                        course_type: this.form.course_type,
                                        student_number: this.student_numbers == null ? null : this.student_numbers.replace(/\n/g, ',').split(',')
                                    },
                                    paramsSerializer: (params) => qs.stringify(params, { encode: false }),
                                };
                                JobService.autoCourseRegistration(config).then(this.callback).catch(e => {
                                    this.showErrors(e, this.$refs.form);
                                });
                            } else if (this.form.job == 'auto_repeat_course_registration') {
                                const config = {
                                    params: {
                                        faculty_code: this.faculty_code,
                                        student_number: this.student_numbers != null ? this.student_numbers.replace(/\n/g, ',') : null
                                    },
                                    paramsSerializer: (params) => qs.stringify(params, { encode: false }),
                                };
                                JobService.autoRepeatCourseRegistration(config).then(this.callback).catch(e => {
                                    this.showErrors(e, this.$refs.form);
                                });
                            } else if (this.form.job == 'auto_irregular_course_registration') {
                                JobService.autoIrregularCourseRegistration().then(this.callback).catch(e => {
                                    this.showErrors(e, this.$refs.form);
                                });
                            } else if (this.form.job == 'create_student_semesters') {
                                JobService.createStudentSemesters(this.form.semester_id).then(this.callback).catch(e => {
                                    this.showErrors(e, this.$refs.form);
                                });
                            } else if (this.form.job == 'assign_doesnt_have_payment_approval') {
                                const config = {
                                    params: {
                                        program_level: this.program_level
                                    },
                                    paramsSerializer: (params) => qs.stringify(params, { encode: false }),
                                };
                                JobService.assignDoesntHavePaymentApproval(this.form.semester_id, config).then(this.callback).catch(e => {
                                    this.showErrors(e, this.$refs.form);
                                });
                            } else if (this.form.job == 'assign_doesnt_have_course_registration') {
                                const config = {
                                    params: {
                                        program_level: this.program_level
                                    },
                                    paramsSerializer: (params) => qs.stringify(params, { encode: false }),
                                };
                                JobService.assignDoesntHaveCourseRegistration(this.form.semester_id, config).then(this.callback).catch(e => {
                                    this.showErrors(e, this.$refs.form);
                                });
                            }
                            else if (this.form.job == 'delete_empty_summer_student_semester') {
                                JobService.deleteEmptySummerStudentSemester(this.form.semester_id).then(this.callback).catch(e => {
                                    this.showErrors(e, this.$refs.form);
                                });
                            }
                            else if (this.form.job == 'job_prep_student_period_status_create') {
                                let formData = {
                                    module_id: this.form.module_id,
                                    student_number: this.student_numbers == null ? null : this.student_numbers.replace(/\n/g, ',').split(',')
                                }

                                JobService.prepStudentsSemesterStatusUpdate(formData).then(response => {
                                    this.callback(response)
                                }).catch(e => {
                                    this.showErrors(e, this.$refs.form)
                                })
                            } else if (this.form.job == 'bulk_address_update') {
                                let formData = {
                                    student_numbers: this.student_numbers == null ? null : this.student_numbers.replace(/\n/g, ',').split(',')
                                }
                                JobService.bulkAddressUpdate(formData).then(response => {
                                    this.callback(response)
                                }).catch(e => {
                                    this.showErrors(e, this.$refs.form)
                                })
                            } else if (this.form.job == 'foreign_student_community_internship_assignment') {
                                let formData = {
                                    student_numbers: this.student_numbers == null ? null : this.student_numbers.replace(/\n/g, ',').split(',')
                                }
                                JobService.foreignStudentCommunityInternshipAssignment(formData).then(response => {
                                    this.callback(response)
                                }).catch(e => {
                                    this.showErrors(e, this.$refs.form)
                                })
                            } else if (this.form.job == 'registrations_bulk_delete') {
                                let formData = {
                                    student_number: this.student_numbers == null ? null : this.student_numbers.replace(/\n/g, ',').split(',')
                                }
                                JobService.registrationsBulkDelete(formData).then(response => {
                                    this._downloadFile(response, 'registrations_bulk_delete.xlsx')
                                }).catch(e => {
                                    this.showErrors(e, this.$refs.form)
                                })
                            }
                            else if (this.form.job == 'course_drop_process') {
                                JobService.courseDropCommand().then(response => {
                                    this.callback(response)
                                }).catch(e => {
                                    this.showErrors(e, this.$refs.form)
                                })
                            } else if (this.form.job == 'edit_class_information') {
                                JobService.studentExcelImport().then(response => {
                                    this.callback(response)
                                }).catch(e => {
                                    this.showErrors(e, this.$refs.form)
                                })
                            }

                            else if (this.form.job == 'update_summer_school_paid_status') {
                                JobService.updateSummerSchoolPaidStatus().then(response => {
                                    this.callback(response)
                                }).catch(e => {
                                    this.showErrors(e, this.$refs.form)
                                })
                            }
                            else if (this.form.job == 'student_programs_graduation_requirement') {
                                JobService.studentProgramsGraduationRequirement().then(response => {
                                    this.callback(response)
                                }).catch(e => {
                                    this.showErrors(e, this.$refs.form)
                                })
                            } else if (this.form.job == 'section_for_repeats') {
                                JobService.createSectionForRepeats().then(response => {
                                    this.callback(response)
                                }).catch(e => {
                                    this.showErrors(e, this.$refs.form)
                                })
                            }
                            else if (this.form.job == 'calculate_number_of_semesters') {
                                JobService.calculateNumberOfSemesters().then(response => {
                                    this.callback(response)
                                }).catch(e => {
                                    this.showErrors(e, this.$refs.form)
                                })
                            }
                            else if (this.form.job == 'max_duration_information_for_advisor') {
                                const config = {
                                  params: {
                                    faculty_code: this.faculty_code,
                                    class: this.form.class,
                                    department_code: this.department_code,
                                    program_code: this.program_code,
                                    advisor_id: this.advisor_id,
                                    program_level: this.program_level,
                                    registration_type: this.registration_type,
                                    customer_group: this.customer_group,
                                  },
                                  paramsSerializer: (params) => qs.stringify(params, { encode: false }),
                                };
                                JobService.updateMaxDurationInformationStart(config).then(this.callback).catch(e => {
                                  this.showErrors(e, this.$refs.form);
                                });
                            }
                            else if (this.form.job == 'search_kps_by_student_numbers') {
                                let formData = {
                                    student_numbers: this.student_numbers == null ? null : this.student_numbers.replace(/\n/g, ',').split(',')
                                }
                                JobService.findPersonFromKpsByStudentNumbers(formData).then(response => {
                                    this.callback(response)
                                }).catch(e => {
                                    this.showErrors(e, this.$refs.form)
                                })
                            }


                            this.faculty_code = null
                            this.form.number = oldNumber;
                        }
                    })

            }
        },
        callback(response) {
            if (response.data.data.log_id) {
                this.$toast.success(this.$t(response.data.message));
                this.$router.push('/job/logs/detail/' + response.data.data.log_id)
            } else if (response.data.data.job_id) {
                if (response.data.message) {
                    this.$toast.success(this.$t(response.data.message));
                } else {
                    this.$toast.success(this.$t('proccess_success'));
                }

                this.$router.push('/job/logs/detail/' + response.data.data.job_id)
            }
        },
        clickFile() {
            this.formProcess = 'import'
            this.$refs.importFormModal.$refs.commonModal.show()
        }
    },
    watch: {
        'form.job'(newVal) {
            this.showSemester = false;
            this.showNumbers = false;

            if (newVal == 'create_student_semesters') {
                this.showSemester = true;
            } else if (newVal == 'assign_doesnt_have_payment_approval') {
                this.showSemester = true;
            } else if (newVal == 'assign_doesnt_have_course_registration') {
                this.showSemester = true;
            }
            else if (newVal == 'delete_empty_summer_student_semester') {
                this.showSemester = true;
            }
            else if (newVal == 'bulk_student_transfer') {
                this.isFile = true
            }
            else if (newVal == 'search_kps_by_student_numbers') {
                this.showNumbers = true
            }

        }
    }
};
</script>
